import request from '@/utils/requestV2'
const qs = require('qs')

// 为渠道分配客户
export function addAdvertiser (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/addadvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 将客户分配给销售
export function addAdvertiserForUser (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/addadvertiserforuser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 渠道行权选择客户
export function chooseAdvertiser (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/chooseadvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 渠道认领无效客户
export function claimAdvertiser (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/claimadvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除渠道下的一个客户
export function deleteAdvertiser (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/deleteadvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 将客户从所属销售移除
export function deleteAdvertiserForUser (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/deleteadvertiserforuser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取渠道下可行权的客户
export function getCanChooseList (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/getcanchooselist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取渠道可认领的无效客户
export function geTinvalidAdvertiserList (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/getinvalidadvertiserlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取品牌可报备的渠道
export function getReportcompany (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/getreportcompany',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 把品牌报备到其他渠道
export function reportbrand (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/reportbrand',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取品牌可跨运营商报备的公司
export function getadxcompany (data) {
  return request({
    url: '/ooh-crm/v1/companyadvertiser/getadxcompany',
    method: 'post',
    data: qs.stringify(data)
  })
}
