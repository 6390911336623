import request from '@/utils/requestV2'
const qs = require('qs')

// order_status 订单状态
// statement_status 结算单状态
// contract_status 合同状态
// brand_status 品牌状态
// purchase_status 预订单状态
// station_level 站点级别
// channel_advertiser_status 渠道客户状态
// publisher_advertiser_status 媒介主客户状态
// msp_task_mark 上刊要求
export function getDictList (data) {
  return request({
    url: '/ooh-sys/v1/dict/getdictlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
